@font-face {
  font-family: YoutubeSans;
  src: url("YouTubeSansRegular.e28ba7f5.otf") format("opentype");
}

:root {
  --fontFamily: YoutubeSans, sans-serif;
}

.squapp-container {
  width: 100%;
  height: 100%;
  min-height: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.squapp-menu-container {
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 8px;
  display: flex;
}

.squapp-menu-item {
  color: #00000059;
  font-family: var(--fontFamily);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  touch-action: none;
  outline: 0;
  font-size: 18px;
}

.squapp-info-container {
  font: bold 20px var(--fontFamily);
  height: 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.squapp-grid-container {
  width: 100%;
  height: 100%;
  min-height: 0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: flex;
  position: absolute;
}

.squapp-grid {
  box-sizing: border-box;
  cursor: pointer;
  background-color: #f0f7fa;
  border: 4px solid #000;
  border-radius: 6px;
  place-items: center;
  display: grid;
  position: relative;
  box-shadow: 0 0 4px 2px #0003;
}

.squapp-grid-overlay {
  width: 100%;
  height: 100%;
  z-index: 9;
  -webkit-backdrop-filter: blur(12px) contrast(.6);
  backdrop-filter: blur(12px) contrast(.6);
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
}

.squapp-grid-modal {
  box-sizing: border-box;
  font: 20px var(--fontFamily);
  white-space: pre-line;
  background-color: #fff;
  border: 4px solid #000;
  border-radius: 8px;
  flex-direction: column;
  gap: 32px;
  margin: 12px;
  padding: 24px;
  display: flex;
  box-shadow: inset 0 0 0 4px #0003;
}

.squapp-grid-cell {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  touch-action: none;
  background-color: #0000;
  outline: 0;
  justify-content: center;
  place-self: center;
  align-items: center;
  display: flex;
  position: relative;
}

.squapp-grid-cell-background {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 2px solid #0003;
  border-radius: 2px;
  position: absolute;
}

.squapp-grid-cell-colored, .squapp-grid-cell-white {
  border: 2px solid #0003;
}

.squapp-grid-cell-transparent {
  border: 2px solid #00000005;
}

.squapp-grid-cell-symbol {
  font: bold 26px var(--fontFamily);
  position: absolute;
}

@keyframes flip {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1, 0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  24% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  74% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*# sourceMappingURL=squapp.css.map */
