@font-face {
    font-family: YoutubeSans;
    src: url("./YouTubeSansRegular.otf") format("opentype");
}

:root {
    --fontFamily: YoutubeSans, sans-serif;
}

.squapp-container {
    width: 100%;
    height: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    -webkit-tap-highlight-color: transparent;
}

.squapp-menu-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    /*height: 32px;*/
    padding: 0px 8px;
    box-sizing: border-box;
}

.squapp-menu-item {
    color: rgba(0, 0, 0, 0.35);
    font-family: var(--fontFamily);
    font-size: 18px;
    cursor: pointer;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: none;
    outline: 0;
}

.squapp-info-container {
    font: bold 20px var(--fontFamily);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
}

.squapp-grid-container {
    width: 100%;
    height: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    gap: 24px;
}

.squapp-grid {
    display: grid;
    justify-items: center;
    align-items: center;
    border: 4px solid black;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: white;
    
    background-color: hsl(200, 50%, 96%);
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    position: relative;
}

.squapp-grid-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    backdrop-filter: blur(12px) contrast(0.6);
    opacity: 1;

    /*display: flex;*/
    display: none;
    justify-content: center;
    align-items: center;
}

.squapp-grid-modal {
    background-color: white;/*rgba(255, 255, 255, 0.99);*/
    border: 4px solid black;
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
    font: 20px var(--fontFamily);
    box-shadow: inset 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
    white-space: pre-line;
    
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 12px;
}

.squapp-grid-cell {
    width: 100%;
    height: 100%;
    justify-self: center;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: none;
    outline: 0;
}

.squapp-grid-cell-background {
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    /*transition: background-color 0.1s ease-in, transform 0.3s, border 0.2s ease-in;*/
}

.squapp-grid-cell-colored {
    border: 2px solid rgba(0, 0, 0, 0.2); /* TODO: 3px for desktop screen, 2px for mobile */
}

.squapp-grid-cell-white {
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.squapp-grid-cell-transparent {
    border: 2px solid rgba(0, 0, 0, 0.02);
}

.squapp-grid-cell-symbol {
    font: bold 26px var(--fontFamily);
    position: absolute;
}

@keyframes flip {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1, 0);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes pop {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    24% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }
    49% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
    74% {
        opacity: 0;
    }

    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
